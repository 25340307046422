<template>
  <div>
    <b-row>
      <b-col
        md="4"
        sm="6"
      >
        <statistic-card-with-line-chart
          icon="BriefcaseIcon"
          :statistic="items.total_works"
          statistic-title="Total Works"
          link="work.list"
        />
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <statistic-card-with-line-chart
          icon="AlertTriangleIcon"
          color="warning"
          :statistic="items.expiring_soon_tasks"
          statistic-title="Tasks Expiring Soon"
        />
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <statistic-card-with-line-chart
          icon="UserCheckIcon"
          color="success"
          :statistic="items.total_users"
          statistic-title="Total Users"
          link="user.list"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BLink, BRow, BCol,
} from 'bootstrap-vue'
import statisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    statisticCardWithLineChart,
  },
  computed: {
    items() {
      return this.$store.getters['user/dashboard/GET_ITEMS']
    },
  },
  mounted() {
    this.getDashboardItems()
  },
  methods: {
    getDashboardItems() {
      this.$store.dispatch('user/dashboard/getItems')
    },
  },
}
</script>

<style>

</style>
